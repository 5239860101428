import React from "react";

const Price = ({ price, discount }) => {
    return <>
        <p className="price">
            <span className="price-price">
                {Price.Format(price)}
            </span>
            {discount > 0 && <>
                <span className="price-discount"><s>{Price.Format(price + discount)}</s></span>
            </>}
        </p>
    </>;
};

Price.Format = (figure) => {
    return figure?.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

export default Price;
