import React, { createContext, useState, useRef, useEffect } from 'react';
import dayjs from 'dayjs';

const SearchContext = createContext({});

export const SearchContextProvider = ({ category, children }) => {
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState({ "hits": [], "aggs": [] });

    const defaultSearchValue = {
        "date": "",
        "adults": 0,
        "children": 0,
        "bedrooms": 0,
        "flexibility": 0,
        "pets": 0,
        "los": 2,
        "filters": {}
    };

    const [searchValue, _setSearchValue] = useState(() => {
        const localData = localStorage.getItem('searchValue');
        return Object.assign(defaultSearchValue, JSON.parse(localData));
    });

    const [datePickerMonth, _setDatePickerMonth] = useState(() => {
        return (searchValue?.date ? dayjs(searchValue.date) : dayjs()).startOf('month');
    });

    const setDatePickerMonth = (date) => {
        _setDatePickerMonth((date ? dayjs(date) : dayjs()).startOf('month'));
    };

    const setSearchValue = (value) => {
        _setSearchValue(value);
        localStorage.setItem('searchValue', JSON.stringify(value));
    };

    const [sorting, setSorting] = useState('capacity_asc');

    const abortController = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            abortController.current?.abort();
            abortController.current = new AbortController();


            try {
                //TODO move this to the service
                const response = await fetch('/availability', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        adults: searchValue.adults,
                        children: searchValue.children,
                        pets: searchValue.pets,
                        bedrooms: searchValue.bedrooms,
                        date: searchValue.date,
                        los: searchValue.los,
                        flexibility: searchValue.flexibility,
                        sorting: sorting,
                        filters: searchValue.filters,
                        category: category
                    }),
                    signal: abortController.current.signal
                });

                const data = await response.json();

                setResults(data);
            }
            catch (e) {
                if (e.name === 'AbortError') return;
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [searchValue, sorting]);

    return (
        <>
            <SearchContext.Provider value={{ searchValue, setSearchValue, loading, results, sorting, setSorting, datePickerMonth, setDatePickerMonth }}>
                {children}
            </SearchContext.Provider>
        </>
    );
};

export default SearchContext;