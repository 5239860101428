import React, { useContext, useState } from "react";
import { Icon } from '@iconify/react';
import Counter from "../Shared/Form/counter";
import { Pluralise } from "../Shared";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween"
import advancedFormat from 'dayjs/plugin/advancedFormat';
import SearchContext from "contexts/searchContext";
import ConfigContext from "contexts/configContext";
import Calendar from "../Shared/Calendar";
import Select from "../Shared/Form/select";
import searchBar from './search.scss';

const CapacityPicker = ({ }) => {
    const { searchValue, setSearchValue } = useContext(SearchContext);

    const setCapacityValue = (value, key) => {
        updateSearchProperty(key, value);
    };

    const updateSearchProperty = (key, value) => {
        setSearchValue({ ...searchValue, [key]: value, "modified": true });
    };

    return <>
        <Counter min={0} max={50} value={searchValue.adults} onChange={setCapacityValue} prop="adults" label="Guests" showWhenMin="Any" />
        {false && <Counter min={0} max={30} value={searchValue.children} prop="children" onChange={setCapacityValue} label="Children" showWhenMin="None" />}
        <Counter min={0} max={30} value={searchValue.bedrooms} prop="bedrooms" onChange={setCapacityValue} label="Bedrooms" showWhenMin="Any" suffix="+" />
        {false && <Counter min={0} max={2} value={searchValue.pets} prop="pets" onChange={setCapacityValue} label="Pets" showWhenMin="None" />}</>;
};

const DatesPicker = () => {
    dayjs.extend(isBetween);
    const { searchValue, setSearchValue, datePickerMonth, setDatePickerMonth } = useContext(SearchContext);
    const { config } = useContext(ConfigContext);

    const getDefaultStartDate = () => {
        return searchValue.date ? searchValue.date : "";
    };

    const getDefaultEndDate = () => {
        if (searchValue.date && searchValue.los)
            return dayjs(searchValue.date).add(searchValue.los, 'day').format("YYYY-MM-DD");

        return "";
    };

    const getDefaultLos = () => {
        return searchValue.los ? searchValue.los : 7;
    };

    const [startDate, setStartDate] = useState(getDefaultStartDate());
    const [endDate, setEndDate] = useState(getDefaultEndDate());
    const [lengthOfStay, setLengthOfStay] = useState(getDefaultLos());

    const setFlexibility = (i) => {
        setSearchValue({ ...searchValue, 'flexibility': i, "modified": true });
    };

    const setLos = (e) => {
        let value = parseInt(e.target.value);
        setLengthOfStay(value);
        updateSearchProperty(startDate, value);
    };

    const calendarBackward = () => {
        if (datePickerMonth.isSame(dayjs(), 'month'))
            return;
        setDatePickerMonth(datePickerMonth.subtract(1, 'month'));
    };

    const calendarForward = () => {
        if (datePickerMonth.isSame(dayjs().add(23, 'months'), 'month'))
            return;
        setDatePickerMonth(datePickerMonth.add(1, 'month'));
    };

    const setDateValue = (value) => {
        if (value.isBefore(dayjs())) return;

        let formatted = value.format("YYYY-MM-DD");

        setStartDate(formatted);
        setEndDate(value.add(lengthOfStay, 'day').format("YYYY-MM-DD"));

        updateSearchProperty(formatted, lengthOfStay);
    };

    const updateSearchProperty = (date, los) => {
        setSearchValue({ ...searchValue, "date": date, "los": los, "modified": true });
    };

    return <>
        <div className="row">
            <div className="col">
                <label className="form-label">Duration</label>
                <Select min={config.MIN_LENGTH_OF_STAY} max={config.MAX_LENGTH_OF_STAY} suffix="night" value={lengthOfStay} onChange={setLos} />
            </div>

            <div className="col">
                <label className="form-label">Flexibility</label>
                <select className="form-select" value={searchValue.flexibility} onChange={(e) => setFlexibility(parseInt(e.target.value))}>
                    {new Array(8).fill().map((e, i) => {
                        return <option key={i} value={i}>
                            {i == 0 ? "Exact Dates" : `+/- ${i} day${i > 1 ? "s" : ""}`}
                        </option>;
                    })}
                </select>
            </div>
        </div>
        <div className="d-flex justify-content-between gap-3 align-items-start flex-column flex-sm-row my-4">

            {[...Array(2)].map((e, i) => {
                let calendarMonth = datePickerMonth.add(i, 'months');
                return <div className="flex-grow-1 w-75 w-sm-100 m-auto" key={`month_${i}`}>
                    <div className="d-flex justify-content-between fs-6">
                        {i == 0 && <button className="btn btn-sm btn-link p-0" onClick={calendarBackward}>
                            <Icon icon="uil:arrow-left" height="auto" />
                        </button>}
                        <span className="flex-grow-1 text-center font-bold">{calendarMonth.format('MMM, YYYY')}</span>
                        {i == 1 && <button className="btn btn-sm btn-link p-0" onClick={calendarForward}>
                            <Icon icon="uil:arrow-right" height="auto" />
                        </button>}
                    </div>
                    <Calendar month={calendarMonth.month() + 1} year={calendarMonth.year()}>
                        {[...Array(calendarMonth.daysInMonth())].map((e, i) => {
                            let date = calendarMonth.add(i, 'days');

                            let classNames = [];

                            if (date.isSame(startDate)) {
                                classNames.push("start");
                                classNames.push("selected");
                            }

                            if (startDate && endDate && date.isBetween(startDate, endDate, null, '[]')) {
                                classNames.push("selected");
                                if (date.isSame(endDate))
                                    classNames.push("end");
                            }

                            if (date.isBefore(dayjs())) {
                                classNames.push("past");
                            }

                            return <Calendar.Day day={date.date()} classnames={classNames} key={i} onClick={() => { setDateValue(date) }} />
                        })}
                    </Calendar></div>;
            })}

        </div>
    </>;

};

const SearchBar = ({ group, close }) => {
    const { searchValue, setSearchValue, results, setSorting, setDatePickerMonth } = useContext(SearchContext);
    let filterAggregations = results.aggs;

    SearchBar.resetSearchGroup = (group) => {
        switch (group) {
            case "Dates":
                setSearchValue({ ...searchValue, date: '', los: 2, flexibility: 0 });
                break;

            case "Guests":
                setSearchValue({ ...searchValue, adults: 0, children: 0, pets: 0, bedrooms: 0 });
                break;

            case "Filters":
                setSearchValue({ ...searchValue, filters: {} });
                break;

            default:
                break;
        }
    };

    const setFilter = (key, value, group) => {
        if (group.radio)
            Object.keys(group.filters).forEach(x => delete searchValue.filters[x]);

        setFiltersValue(
            {
                ...searchValue.filters,
                [key]: value
            })
    };

    const updateSearchProperty = (key, value) => {
        setSearchValue({ ...searchValue, [key]: value, "modified": true });
    };

    const getAggregatedFilterCount = (filter) => {
        return filterAggregations?.find(x => x.key == filter)?.doc_count || 0;
    };

    const setFiltersValue = (value) => {
        updateSearchProperty("filters", value);
    };

    let groupedFilters = [
        {
            "group": "Location",
            "radio": true,
            "filters": {
                "327582": "Village",
                "235545": "Rural",
                "327583": "Town"
            }
        },
        {
            "group": "About The Property",
            "filters": {
                "327589": "Corporate Venue",
                "233066": "Hen or Stag Friendly",
                "233068": "Perfect for Two",
                "327591": "Great for Families",
                "327594": "Pet Free",
                "228617": "Dog Friendly",
                "327584": "Scenic Views",
                "327585": "Pub within 1 mile",
                "499473": "Shop within 1 mile",
                "496872": "All Ensuite"
            }
        },
        {
            "group": "Facilities",
            "columns": 2,
            "filters": {
                "327587": "Games Room",
                "440845": "Games Room (Communal)",
                "499474": "Garden/Outdoor Space",
                "496870": "Free Wifi",
                "475394": "Free on Site Parking",
                "496871": "BBQ",
                "496874": "Outdoor furniture",
                "497483": "Coffee machine",
                "497482": "Washing machine",
                "327595": "Log Burner",
                "348034": "EV Charger",
                "460052": "Ground Floor Bedroom",
                "245973": "Wood Fired Hot Tub",
                "233067": "Cycle Storage"
            }
        }
    ];

    const Header = ({ children }) => {
        return <>
            <h4 className="search-bar-group-header m-0">
                {children}
            </h4>
        </>;
    };

    const Group = ({ header, isFiltered, classNames, children }) => {
        return <div className={`search-bar-group flex-column p-1 ${classNames || ""}`}>
            <div className="d-flex justify-content-between align-items-center w-100">
                {header && <Header>{header}</Header>}
                {isFiltered && <button className="btn d-flex align-items-center text-danger p-0" onClick={() => { SearchBar.resetSearchGroup(group) }}><Icon icon='circum:undo' height='auto'></Icon> Clear</button>}
            </div>
            <div className="w-100">{children}</div>
            <div className="text-center">
                <button className="btn btn-primary btn-sm mt-2" onClick={close}>Done</button>
            </div>
        </div>;
    }

    return <div className="container search-bar d-flex flex-column align-items-center">
        {group == "Guests" && <Group header="Guests" isFiltered={searchValue.adults + searchValue.children + searchValue.bedrooms + searchValue.pets > 0}
            classNames={"col-12 col-md-5"}>
            <CapacityPicker />
        </Group>}

        {group == "Dates" && <Group header="Dates" classNames="col-12 col-lg-9 col-xl-7" isFiltered={searchValue.date != ""}>
            <DatesPicker />
        </Group>}

        {group == "Filters" && <Group header="Filters" isFiltered={Object.keys(searchValue.filters) > 0}>
            <div className="m-2 d-flex flex-column align-items-start flex-md-row gap-2 gap-md-4">
                {groupedFilters.map(group => <div key={group.group} className="flex-1">
                    <p className="mb-1"><b>{group.group}</b></p>
                    <div className="m-2" style={{ "columnCount": group.columns }}>
                        {Object.keys(group.filters).map(key => {
                            let aggCount = getAggregatedFilterCount(key);
                            return <div className="form-check" key={key}>
                                <input disabled={aggCount < 1 && !searchValue.filters[key]} className="form-check-input" name={group.group} type={group.radio ? "radio" : "checkbox"} checked={searchValue.filters[key] || false} onChange={e => setFilter(key, e.target.checked, group)} id={key} />
                                <label className="form-check-label" htmlFor={key}>
                                    {group.filters[key]}
                                    <span className="text-muted mx-1">({aggCount})</span>
                                </label>
                            </div>;
                        })}
                    </div>
                </div>)}
            </div>
        </Group>}
    </div >;
};

const SearchBarSimple = ({ edit }) => {
    const { searchValue, setSearchValue, setSorting, setDatePickerMonth } = useContext(SearchContext);
    const filtersInUse = Object.values(searchValue.filters).filter(x => x).length;

    const resetSearch = () => {
        setSearchValue({
            date: '',
            adults: 0,
            children: 0,
            flexibility: 0,
            pets: 0,
            bedrooms: 0,
            los: 2,
            filters: {},
            modified: false
        });
        setDatePickerMonth('');
        setSorting("capacity_asc");
    };

    const SearchBarSimpleButton = ({ icon, colour, children, action }) => {
        return <>
            <button className="btn btn-primary d-flex flex-column flex-md-row gap-2 align-items-center justify-content-center p-1" onClick={action}>
                <Icon icon={icon} color={colour} height="auto" />
                {children}
            </button>
        </>;
    };

    return <div className="py-1 d-flex container justify-content-between justify-content-md-start   gap-2 gap-md-5">
        <SearchBarSimpleButton icon="mdi:calendar" action={() => edit(true, 'Dates')}>
            {!searchValue.date && "Any Dates"}
            {searchValue.date && <span>{dayjs(searchValue.date).format("ddd, MMM D")}
                <span className="px-1">-</span>
                {dayjs(searchValue.date).add(parseInt(searchValue.los), 'day').format("ddd, MMM D")}</span>}
        </SearchBarSimpleButton>

        <SearchBarSimpleButton icon="iconoir:group" action={() => edit(true, 'Guests')}>
            <>
                <Pluralise number={searchValue.adults + searchValue.children} noun="Guest" whenZero="Any Size" />
                {searchValue.bedrooms > 0 && <>, <Pluralise number={searchValue.bedrooms} noun="Room" whenZero="" /></>}
                {searchValue.pets > 0 && <>, <Pluralise number={searchValue.pets} noun="Pet" whenZero="" /></>}
            </>
        </SearchBarSimpleButton>

        <SearchBarSimpleButton icon="lets-icons:filter" action={() => edit(true, 'Filters')}>
            <Pluralise number={filtersInUse} noun="Filter" whenZero="No Filters" />
        </SearchBarSimpleButton>

        {searchValue.modified && <SearchBarSimpleButton action={() => resetSearch()} icon="material-symbols:undo"> <span className="d-none d-md-inline" id="btn-search-start-over">Start Over</span></SearchBarSimpleButton>}
    </div>;
};

const SearchBarEntry = ({ edit }) => {
    dayjs.extend(advancedFormat);
    const { searchValue } = useContext(SearchContext);

    const SearchBarEntryButton = ({ title, children, summary, name, size, onClose }) => {
        return <div className={`d-flex flex-column p-2 flex-fill flex-shrink-0 ${size}`}>
            <span onClick={() => edit(true, name)} className="fs-5 text-dark"><b>{title}</b></span>
            <button className="btn btn-outline-dark" onClick={() => edit(true, name)}>{summary}</button>
        </div>;
    };

    return <div className="search-bar-entry d-flex flex-column flex-xl-row gap-4 gap-md-2">
        <SearchBarEntryButton title="Staying When?" name="Dates" size="" summary={<>
            {searchValue.date && <>{dayjs(searchValue.date).format("ddd Do MMM")} <Icon icon="teenyicons:arrow-right-solid" /> {dayjs(searchValue.date).add(searchValue.los, 'day').format("ddd Do MMM, YYYY")}</>}
            {!searchValue.date && "Any Time"}
        </>}>
            <DatesPicker />
        </SearchBarEntryButton>
        <SearchBarEntryButton title="Who's Coming?" size="" name="Guests" summary={<Pluralise number={searchValue.adults + searchValue.children} noun="Guest" whenZero="Not Sure" />}>
            <CapacityPicker />
        </SearchBarEntryButton>

        <div className="d-flex flex-column justify-content-end p-2">
            <a id="btn-availability-search" className="btn btn-primary" href="/venues"><Icon icon='ic:twotone-search' height='auto' /> Search</a>
        </div>
    </div>;
};

export { SearchBarSimple, SearchBarEntry };
export default SearchBar;