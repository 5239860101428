import React, { useRef, useState, useEffect } from 'react';
import { Price, BookNow, Pluralise } from '../Shared';
import AvailabilityService from '../../services/AvailabilityService';
import MonthView from './MonthView';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Icon } from '@iconify/react';

import styles from './calendar.scss';

const AvailabilityCalendar = ({ numMonths, venueRef, venueName, colSizes }) => {
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(advancedFormat);

    const [pricingData, setPricingData] = useState({});
    const [startMonth, setStartMonth] = useState(dayjs().startOf('month'));

    const resetSelectionOnNavigate = false;
    const resetSelection = () => {
        setSelectedStartDate();
        setSelectedEndDate();
    };

    const nextMonth = () => {
        if (startMonth.isAfter(dayjs().add(24 - numMonths, 'months'))) return;

        if (resetSelectionOnNavigate) resetSelection();
        setStartMonth(startMonth.add(1, 'month'));
    };

    const canGoBack = () => {
        return (startMonth.isSameOrAfter(dayjs()));
    };

    const prevMonth = () => {
        if (!canGoBack()) return;

        if (resetSelectionOnNavigate) resetSelection();
        setStartMonth(startMonth.subtract(1, 'month'));
    };

    const [calendarData, setCalendarData] = useState({});
    const [loading, setLoading] = useState(false);

    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();

    const abortController = useRef(null);

    useEffect(() => {
        async function fetchData() {
            abortController.current?.abort();
            abortController.current = new AbortController();

            let date = startMonth;
            //setCalendarData({}); //Don't show old results while fetching new ones
            setLoading(true);
            try {
                let data = await AvailabilityService.getAvailabilityForVenue(venueRef, date, numMonths, abortController.current.signal);

                setCalendarData(Object.assign(calendarData, data));
            } catch (e) {
                if (e.name === 'AbortError') return;
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [startMonth, venueRef]);

    const abortController2 = useRef(null);
    useEffect(() => {
        async function fetchData() {
            abortController2.current?.abort();
            abortController2.current = new AbortController();

            setLoading(true);

            try {
                const data = await AvailabilityService.getPriceForVenue(venueRef, selectedStartDate, selectedEndDate, abortController2.current.signal);

                setPricingData(data);
            } catch (e) {
                if (e.name === 'AbortError') return;
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }

        if (selectedStartDate && selectedEndDate)
            fetchData();
        else
            setPricingData({});

    }, [selectedStartDate, selectedEndDate]);

    return <div className="row">
        <div className={`col ${colSizes}`}>
            <div className="d-flex justify-content-between flex-wrap flex-sm-nowrap gap-1 gap-lg-3 flex-grow-1">
                <button className="btn btn-link p-0 fs-3" onClick={prevMonth}><Icon icon="uil:arrow-left" /></button>
                {
                    [...Array(numMonths)].map((e, i) => {

                        let month = startMonth.add(i, 'month');
                        return <MonthView key={i} month={month.format('M')} year={month.format('YYYY')} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} setSelectedStartDate={setSelectedStartDate} setSelectedEndDate={setSelectedEndDate} calendarData={calendarData} />
                    })
                }
                <button className="btn btn-link p-0 fs-3" onClick={nextMonth}><Icon icon="uil:arrow-right" /></button>
            </div>
            <div className="d-flex flex-column flex-wrap p-2 gap-2 w-md-25">
                {selectedStartDate && <p><button className="btn btn-link p-0" onClick={resetSelection}>Reset Selection</button></p>}
                {!selectedStartDate && <p>Please select your arrival date to see prices and place a booking.</p>}
                {selectedStartDate && !selectedEndDate && <>
                    <p>Now, please select your departure date.</p>
                    <p>Minimum stay: <Pluralise number={calendarData[selectedStartDate]?.['min_stay']} noun="night" /></p>
                    <p>Maximum stay: <Pluralise number={calendarData[selectedStartDate]?.['max_stay']} noun="night" /></p>
                </>}
                {selectedStartDate && selectedEndDate && <>
                    <p>{dayjs(selectedStartDate).format("ddd Do MMM")} &mdash; {dayjs(selectedEndDate).format("ddd Do MMMM, YYYY")} <span className="d-block text-muted">{dayjs(selectedEndDate).diff(selectedStartDate, 'days')} nights</span></p>
                    {pricingData.length && <div>
                        <Price price={pricingData[0].price} discount={pricingData[0].discount} /> <span className="d-block text-muted">Based on a maximum occupancy of {pricingData[0].max_occupancy}</span>
                        <p className="my-3">
                            <BookNow venueRef={venueRef} venueName={venueName} arrivalDate={selectedStartDate} los={Math.abs(dayjs(selectedEndDate).diff(selectedStartDate, 'day'))} capacity={2} />
                        </p>
                    </div>}
                </>}
            </div>
        </div>
    </div>;
};

export default AvailabilityCalendar;