import React, { useEffect, useContext } from "react";
import { useState } from "react";
import dayjs from "dayjs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import AvailabilityService from "services/AvailabilityService";
import SearchContext from "contexts/searchContext";

const BookNow = ({ venueRef, arrivalDate, los, capacity, venueName }) => {

    const [unavailable, setUnavailable] = useState(false);
    const [checking, setChecking] = useState(false);
    const { searchValue } = useContext(SearchContext);
    useEffect(() => {
        setUnavailable(false);

    }, [searchValue]);

    const getSanitizedName = (name) => {
        return name?.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
    };

    const checkAvailableAndBook = async () => {
        let checkIn = dayjs(arrivalDate);
        let checkOut = checkIn.add(los, 'day');
        let occupancy = capacity || (searchValue?.adults + searchValue?.children);

        setChecking(true);

        let data = await AvailabilityService.checkAvailability(venueRef, checkIn.format("YYYY-MM-DD"), los, occupancy);

        if (data && data.available) {

            //Open the SC popup booking dialog. 
            //For this to work it needs the SC iframe of calendars for ALL properties 
            //to be on the page (and ready).

            let cartDetails = {
                action: "cartOpen",
                arrivalDate: checkIn.format("DD/MM/YYYY"),
                departureDate: checkOut.format("DD/MM/YYYY"),
                numberNights: los,
                capacity: capacity,
                cottageID: venueRef,
                isDetailsPassed: true
            };

            //Fingers crossed this works!
            window.postMessage(cartDetails, "*");

            //TODO catch errors and maybe send them to SC to book there 
        } else {
            setUnavailable(true);
        }

        setChecking(false);
    };

    return <>
        <OverlayTrigger
            show={unavailable && !checking}
            placement="top"
            overlay={<Tooltip>Unfortunately, this property is no longer available for the dates you have selected. Please try again with different dates.</Tooltip>}
        >
            <button id={`book-now-btn-${getSanitizedName(venueName)}`} className={`btn btn-primary btn-lg d-flex align-items-center gap-2`} onClick={checkAvailableAndBook} disabled={checking}>
                {checking && <span className="spinner-border spinner-border-sm"></span>} Book Now</button>
        </OverlayTrigger>
    </>;
};

export default BookNow;